import {  defineMessages } from 'react-intl'

const messages = defineMessages({
  test: {
    id: 'app.test',
    defaultMessage: 'Testing message'
  },
  close: {
    id: 'button.close',
    defaultMessage: 'Close'
  },
  or_close: {
    id: 'button.or_close',
    defaultMessage: 'Or close'
  },
  back: {
    id: 'button.back',
    defaultMessage: 'Back'
  },
  cancel: {
    id: 'button.cancel',
    defaultMessage: 'cancel'
  },
  next: {
    id: 'button.next',
    defaultMessage: 'next'
  },
  submit: {
    id: 'button.submit',
    defaultMessage: 'submit'
  },
  save: {
    id: 'button.save',
    defaultMessage: 'save'
  },
  accept_changes_button: {
    id: 'button.accept_changes',
    defaultMessage: 'Accept changes'
  },
  confirmed: {
    id: 'general.confirmed',
    defaultMessage: 'Yes'
  },
  name: {
    id: 'Name',
    defaultMessage: 'Name'
  },
  rename:{
    id:"Rename",
    defaultMessage:'Rename'
  },
  email: {
    id: 'email',
    defaultMessage: 'Email'
  },
  phone_number: {
    id: 'phone_number',
    defaultMessage: 'Phone Number'
  },
  mandatory_field: {
    id: 'mandatory_field',
    defaultMessage: 'Mandatory field'
  },
  signout_button: {
    id: 'button.signout',
    defaultMessage: 'Sign Out'
  },
  create: {
    id: 'Create',
    defaultMessage: 'Create'
  },
  update: {
    id: 'Update',
    defaultMessage: 'Update'
  },
  created_at: {
    id: 'created_at',
    defaultMessage: 'Created At'
  },
  manage: {
    id: 'manage',
    defaultMessage: 'Manage'
  },
  delete: {
    id: 'button.delete',
    defaultMessage: 'Delete'
  },
  confirm: {
    id: 'button.confirm',
    defaultMessage: 'Confirm'
  },
  yes: {
    id: 'Yes',
    defaultMessage: 'Yes'
  },
  no: {
    id: 'No',
    defaultMessage: 'No'
  },
  ok: {
    id: 'button.ok',
    defaultMessage: 'OK'
  },
  search_placeholder: {
    id: 'workout.contentfilter.searchfield.placeholder',
    defaultMessage: '  Type at least 3 characters to search'
  },
  total_number_of_organizations: {
    id: 'organization.total_number_of_organizations',
    defaultMessage: 'Total Number of Organizations:'
  },
  save_and_next: {
    id: 'button.save_and_next',
    defaultMessage: 'Save and next'
  },
  address: {
    id: 'address',
    defaultMessage: 'Address'
  },
  required_field: {
    id: 'messages.this_field_is_required',
    defaultMessage: 'This field is required.'
  },
  country: {
    id: 'country',
    defaultMessage: 'Country'
  },
  state_province: {
    id: 'state_province',
    defaultMessage: 'State/Province'
  },
  first_name: {
    id: 'client.first_name',
    defaultMessage: 'First Name'
  },
  last_name: {
    id: 'client.last_name',
    defaultMessage: 'Last Name'
  },
  kcal: {
    id: 'workout.kcal',
    defaultMessage: "KCal"
  },
  no_limit: {
    id: 'dateTimeFilter.NO_LIMIT',
    defaultMessage: 'No date limit'
  },
  days_1: {
      id: 'dateTimeFilter.DAYS_1',
      defaultMessage: ' 1 Day'
  },
  days_7: {
      id: 'dateTimeFilter.DAYS_7',
      defaultMessage: ' 7 Days'
  },
  days_10: {
      id: 'dateTimeFilter.DAYS_10',
      defaultMessage: ' 10 Days'
  },
  days_30: {
      id: 'dateTimeFilter.DAYS_30',
      defaultMessage: ' 30 Days'
  },
  days_60: {
      id: 'dateTimeFilter.DAYS_60',
      defaultMessage: ' 60 Days'
  },
  days_90: {
      id: 'dateTimeFilter.DAYS_90',
      defaultMessage: ' 90 Days'
  },
  changes_saved: {
    id: 'messages.changes_saved',
    defaultMessage: ' Changes saved!'
  },
  archive: {
    id: 'button.archive',
    defaultMessage: 'Archive'
  },
  archived:{
    id: 'button.archived',
    defaultMessage:"Archived"
  },
  unarchive: {
    id: 'button.unarchive',
    defaultMessage: 'Unarchive'
  },
  unarchived:{
    id:'button.unarchived',
    defaultMessage:'Unarchived'
  },
  last_sign_in_options_filter: {
    id: 'dateTimeFilter.last_sign_in_options_filter',
    defaultMessage: 'Last Sign In'
  },
  back_to_sign_in: {
    id: 'button.back_to_sign_in',
    defaultMessage: 'Back to Sign in'
  },
  good_for_and_counter_productive_for_can_not_have_the_same_values: {
    id: 'nutrition_plan.good_for_and_counter_productive_for_can_not_have_the_same_values',
    defaultMessage: 'Good for and counterproductive for can not have the same values'
  },
  no_items_filter: {
    id: 'messages.no_items_filter',
    defaultMessage: 'No items match your filter options.'
  },
  password_must_contain_at_least_six_characters: {
    id: 'messages.password_must_contain_at_least_six_characters',
    defaultMessage: 'Password must contain at least 6 characters.'
  },
  password_and_confirm_password_must_be_match: {
    id: 'messages.password_and_confirm_password_must_be_match',
    defaultMessage: 'Password and confirm password must be match.'
  },
  program_week: {
    id: 'program.week',
    defaultMessage: 'Week'
  },
  save_changes: {
    id: 'button.save_changes',
    defaultMessage: 'Save Changes'
  },
  change_password: {
    id: 'trainer_profile.change_password',
    defaultMessage: 'Change Password'
  },
  confirm_password: {
    id: 'organization.confirm_password',
    defaultMessage: 'Confirm Password'
  },
  error_loading: {
    id: 'messages.error_loading',
    defaultMessage: 'There was an error loading this page. Please refresh the page. If the problem persists, please contact support.'
  },
  exercises: {
    id: 'Exercises',
    defaultMessage: 'Exercises'
  },
  workouts: {
    id: 'Workouts',
    defaultMessage: 'Workouts'
  },
  programs: {
    id: 'Programs',
    defaultMessage: 'Programs'
  },
  organizations: {
    id: 'Organizations',
    defaultMessage: 'Organizations'
  },
  remove: {
    id: 'button.remove',
    defaultMessage: 'Remove'
  },
  add_the_basic_details: {
    id: 'nutrition_plan.add_the_basic_details',
    defaultMessage: 'Add basic details and a video.'
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title'
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description'
  },
  success: {
    id: 'messages.success',
    defaultMessage: 'Changes saved successfully!'
  },
  error: {
      id: 'messages.error',
      defaultMessage: 'Changes not saved! Please try again. If the problem persists, please contact support.'
  },
  link: {
    id: 'messages.link',
    defaultMessage: 'Link'
  },
  header_note: {
    id: 'workout.edit.header_note',
    defaultMessage: 'Please use dot(.) as decimal separator for number fields'
  },
  billing_details: {
    id: 'billing.billing_details',
    defaultMessage: 'Billing Details'
  },
  role: {
    id: 'Role',
    defaultMessage: 'Role'
  },
  all: {
    id: 'premium.all',
    defaultMessage: 'ALL'
  }, 
  none: {
    id: 'premium.none',
    defaultMessage: 'NONE'
  },
  january: {
    id: 'months.january',
    defaultMessage: 'January'
  },
  february: {
    id: 'months.february',
    defaultMessage: 'February'
  },
  march: {
    id: 'months.march',
    defaultMessage: 'March'
  },
  april: {
    id: 'months.april',
    defaultMessage: 'April'
  },
  may: {
    id: 'months.may',
    defaultMessage: 'May'
  },
  june: {
    id: 'months.june',
    defaultMessage: 'June'
  },
  july: {
    id: 'months.july',
    defaultMessage: 'July'
  },
  august: {
    id: 'months.august',
    defaultMessage: 'August'
  },
  september: {
    id: 'months.september',
    defaultMessage: 'September'
  },
  october: {
    id: 'months.october',
    defaultMessage: 'October'
  },
  november: {
    id: 'months.november',
    defaultMessage: 'November'
  },
  december: {
    id: 'months.december',
    defaultMessage: 'December'
  },
  number: {
    id: 'number',
    defaultMessage: 'Number'
  },
  clients: {
    id: 'Clients',
    defaultMessage: 'Clients'
  },
  delete_client: {
    id: 'delete_client',
    defaultMessage: 'Delete Client'
  },
  can_add_new_clients: {
    id: 'Can Add New Clients?',
    defaultMessage: 'Can Add New Clients?'
  },
  copy_org_content: {
    id: 'copy_org_content',
    defaultMessage: 'Can Copy ORG Content'
  },
  actions: {
    id: 'Actions',
    defaultMessage: 'Actions'
  },
  trainer: {
    id: 'role.trainer',
    defaultMessage: 'Trainer'
  },
  sign_in: {
    id: 'login_page.sign_in',
    defaultMessage: 'Sign In'
  },
  register: {
    id: 'button.register',
    defaultMessage: 'Register'
  },
  email_wl_subject: {
    id: 'profile_image.email_wl_subject',
    defaultMessage: '{name} - Contact Me'
  },
  email_WL_subject_message: {
      id: 'messages.email_WL_subject',
      defaultMessage: '{name} - Contact Me'
  },
  email_WL_body: {
      id: 'messages.email_WL_body',
      defaultMessage: 'Hello, I have seen the content on your profile on the {netName} and would like to get some more information about the services you offer?'
  },
  error_saving_data: {
    id: 'messages.error_saving_data',
    defaultMessage: 'There was an error saving your data.'
  }
})

export default messages;
