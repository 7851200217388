import {useCallback, useEffect, useState} from "react";
import {Meal, Resource} from "myfitworld-model";
import ContentFilterValues from "../model/ContentFilter";
import useOrganization from "./useOrganization";
import { useMfwThemeProvider } from "myfitworld-utils";

function useEntityList<T extends Resource>(
  listProvider: (organizationId: string, wlOrgId:string, filter?: ContentFilterValues, sortKey?: string) => Promise<Array<T>>,
  filter?: ContentFilterValues,
  sortKey?: string,
) {
  const [ data, setData ] = useState<Array<T>>([]);
  const [ loading, setLoading ] = useState<boolean>(false);
  
  const {organizationId} = useOrganization();
  const {theme} = useMfwThemeProvider();
  const loadData = useCallback(() => {
    if(organizationId) {
      (async () => {
        setLoading(true);
        const result = await listProvider(organizationId, theme.orgId, filter, sortKey);
        setLoading(false);
        setData(result);
      })()
    }
  }, [organizationId, listProvider, filter, sortKey]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  
  return { data, loadData, loading };
}

export default useEntityList;
