import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import {Enumerable} from 'myfitworld-model';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import getLocalizedProperty from "../utils/getLocalizedProperty";
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      width: 48,
    }
  }),
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SelectField = ({
  label,
  options,
  value,
  onChange,
  required = false,
  clearable = false,
  disabled = false,
} : {
  label: string,
  options: Array<Enumerable>,
  value?: Array<string>,
  onChange: (value: any) => void,
  required?: boolean,
  clearable?: boolean,
  disabled?: boolean,
}) => {
  const classes = useStyles();
  const {locale} = useIntl();

  return (
    <FormControl
      size='small'
      variant='filled'
      fullWidth
      required={required}
      margin="dense"
    >
      <InputLabel>
        {label}
      </InputLabel>
      <Select
        value={value}
        MenuProps={MenuProps}
        onChange={e => onChange((e as any).target.value) }
        disabled={disabled}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {getLocalizedProperty(locale, option.name)}
          </MenuItem>
        ))}
      </Select>
      {clearable &&
        <IconButton
          aria-label='clear'
          className={classes.icon}
          onClick={() => onChange('')}
          disabled={disabled}
        >
          <ClearIcon />
        </IconButton>
      }
    </FormControl>
  );
};

export default SelectField;
