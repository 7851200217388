import {useStoreState} from 'pullstate';
import {differenceInSeconds} from "date-fns";
import {useCallback, useEffect, useState} from "react";
import {
  categoryApi,
  counterProductiveForApi,
  dietApi,
  durationInWeeksApi,
  equipmentApi, expertisesApi,
  fitnessActivitiesApi, glycemicIndexRangeApi, glycemicLoadRangeApi, goalsApi,
  goodForApi,
  healthIssuesApi,
  mealTypesApi,
  micronutrientApi,
  motorSkillsApi,
  muscleGroupsApi,
  sportsApi,
  timePreparationApi,
  durationConfigurationApi,
  //totalKcalApi
} from "./common";
import globalState from '../globalState';
import useOrganization from "../hooks/useOrganization";
import { useMfwThemeProvider } from 'myfitworld-utils';
import { useIntl } from 'react-intl';

const CACHE_LIFE = 60; // seconds

export type GlobalStateKey =
  'equipmentCache' | 'muscleGroupsCache' | 'sportsCache' | 'motorSkillsCache' |
  'healthIssuesCache' | 'fitnessActivitiesCache' | 'goalsCache' | 'expertisesCache' |
  'mealTypesCache' | 'dietCache' | 'goodForCache' | 'counterProductiveForCache' | 'timePreparationCache' | 'categoryCache' | 'micronutrientCache'
  | 'glycemicIndexRangeCache' | 'glycemicLoadRangeCache' | 'durationInWeeksCache' | 'durationConfigurationCache';

const getListApiForKey = (key: GlobalStateKey) => {
  switch (key) {
    case 'equipmentCache':
      return equipmentApi.list;
    case 'muscleGroupsCache':
      return muscleGroupsApi.list;
    case 'sportsCache':
      return sportsApi.list;
    case 'motorSkillsCache':
      return motorSkillsApi.list;
    case 'healthIssuesCache':
      return healthIssuesApi.list;
    case 'fitnessActivitiesCache':
      return fitnessActivitiesApi.list;
    case 'goalsCache':
      return goalsApi.list;
    case 'expertisesCache':
      return expertisesApi.list;
    case 'mealTypesCache':
      return mealTypesApi.list;
    case 'counterProductiveForCache':
      return counterProductiveForApi.list;
    case 'dietCache':
      return dietApi.list;
    case 'goodForCache':
      return goodForApi.list;
    case 'timePreparationCache':
      return timePreparationApi.list
    // case 'totalKcalCache':
    //   return totalKcalApi.list
    case 'categoryCache':
      return categoryApi.list
    case 'micronutrientCache':
      return micronutrientApi.list
    case 'glycemicIndexRangeCache':
      return glycemicIndexRangeApi.list
    case 'glycemicLoadRangeCache':
      return glycemicLoadRangeApi.list
    case 'durationInWeeksCache' :
      return durationInWeeksApi.list
    case 'durationConfigurationCache':
      return durationConfigurationApi.list

  }
}

const useGlobalCachedResourceList = <T>(
  globalStateKey: GlobalStateKey
) => {
  const [data, setData] = useState<Array<T>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const {organizationId} = useOrganization();
  const cached = useStoreState(globalState, s => s[globalStateKey]);
  const {theme} = useMfwThemeProvider();
  const intl = useIntl();
  const reload = (
    cached?.data.length === 0 ||
    Math.abs(differenceInSeconds(new Date(), cached?.loadedAt!!)) > CACHE_LIFE
  );

  const loadData = useCallback(async () => {
    setLoading(true);

    if (reload) {
      const result = await getListApiForKey(globalStateKey)(organizationId || theme.orgId, theme.orgId, undefined, `name.${intl.locale}`);
      setData(result as unknown as T[]);
      setLoading(false);
      globalState.update(state => {
        state[globalStateKey] = {
          data: result,
          loadedAt: new Date(),
        }
      })

    } else {
      setData(cached?.data as unknown as T[]);
      setLoading(false);
    }

  }, [organizationId, globalStateKey, reload, cached?.data]);

  useEffect(() => {
    loadData();
  }, []);

  return {data, loadData, loading};
}

export default useGlobalCachedResourceList;
