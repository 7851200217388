import React, {PropsWithChildren} from 'react';
import {Button, Drawer, useMediaQuery} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useIntl } from 'react-intl';
import globalMessages from '../messages';
import { useMfwThemeProvider } from 'myfitworld-utils';
import { useDrawerStyles } from '../theme/useDrawerStyles';

export interface SideDrawerProperties {
  isOpen: boolean;
  onClose: () => void;
}

export default function ({children, isOpen, onClose}: PropsWithChildren<SideDrawerProperties>) {
  const isMobile = useMediaQuery('960px');
  const {mfwMuiWebTheme} = useMfwThemeProvider();
  const useStyles = useDrawerStyles(mfwMuiWebTheme, isMobile);
  const classes = useStyles();
  const {formatMessage} = useIntl();

  return (
    <Drawer
      classes={{paper: classes.drawerPaper}}
      variant="persistent"
      anchor="right"
      open={isOpen}
    >
      <div className={classes.drawerHeader}>
        <Button
          size="small"
          variant="outlined"
          startIcon={<CloseIcon/>}
          onClick={_ => onClose()}
        >
          {formatMessage(globalMessages.close)}
        </Button>
      </div>
      {children}
    </Drawer>
  )
}
