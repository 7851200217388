import { OrganizationUser, Role } from "myfitworld-model";
import { TrainerListItem } from "../../api/firebaseQueries/Organizations/trainers";
import { User } from "myfitworld-model/src";
import globalMessages from '../../messages';
import { MessageDescriptor } from "react-intl";
export interface UsersTableProps {
  roles: Role[];
  onClick?: (userId: string, user: OrganizationUser) => void;
  showRole: boolean;
  showWarnings?: boolean;
  disabled?: boolean;
  selectedTab?: any;
}
export interface RemoveUserDialogProps {
  delete: boolean;
  anonymize: boolean;
  orgUser: OrganizationUser | null;
  isClickOnRowEnabled: boolean;
  dialogLoader: boolean;
}

export interface DateTimeOptionsField{
  id: string;
  name: string[];
}

export const DateTimeOptions:DateTimeOptionsField[]= [
  { id: "ALL", name:["no_limit"] },
  { id: "DAYS_1", name: ["days_1" ]},
  { id: "DAYS_7", name: ["days_7" ]},
  { id: "DAYS_10", name: ["days_10" ]},
  { id: "DAYS_30", name: ["days_30" ]},
  { id: "DAYS_60", name: ["days_60" ]},
  { id: "DAYS_90", name: ["days_90"] },
];

export function getDateTimeOptionsWithTranslation({formatMessage}:{formatMessage:(prop:MessageDescriptor)=>string}){
  return DateTimeOptions.map(options => ({
    id: options.id,
    name: {
      en: formatMessage((globalMessages as any)[options.name[0]]),
      sr: formatMessage((globalMessages as any)[options.name[0]]),
      de: formatMessage((globalMessages as any)[options.name[0]]),
      hr: formatMessage((globalMessages as any)[options.name[0]]),
      es: formatMessage((globalMessages as any)[options.name[0]]),
      ro: formatMessage((globalMessages as any)[options.name[0]]),
      bg: formatMessage((globalMessages as any)[options.name[0]]),
      ru: formatMessage((globalMessages as any)[options.name[0]]),
    }
  }));
}

export function getTrainerNames (trainerIds: string[] | undefined, allTrainerList: TrainerListItem[] | undefined | null){
  if(!trainerIds) return [];
  let trainerNames: string[] = [];
  allTrainerList?.map((trainer)=>{
    if (trainerIds.includes(trainer.id)){
      trainerNames.push(trainer.name);
    }
  })
  return trainerNames;
}

export function isAdmin(authUser: User | null){
  if(authUser){
    const currentOrganization = authUser?.currentOrganization;
    const organization = authUser?.organizations?.find((org)=>{
      if(org.id === currentOrganization) return org;
    })
    if(Role.Admin == organization?.role || Role.SuperAdmin == organization?.role || authUser.isSuperAdmin){
      return true
    } return false;
  } else return false;
}