import {  defineMessages } from 'react-intl'

const messages = defineMessages({
  error_delete_Organization_Client: {
    id: 'error.deleteOrganizationClient',
    defaultMessage: 'Error deleting organization client!'
  },
  error_loading_Organization_Clients: {
    id: 'error.loadingOrganizationClients',
    defaultMessage: 'Error loading organization clients!'
  },
  error_loading_Organization_Memebers: {
    id: 'error.loadingOrganizationMembers',
    defaultMessage: 'Error loading organization members!'
  },
  error_loading_Organization_TrainerList: {
    id: 'error.loadingOrganizationTrainerList',
    defaultMessage: 'Error loading organizations trainer list!'
  },
  error_updating_Organizations_In_User: {
    id: 'error.loadingOrganizationsInUser',
    defaultMessage: 'Error updating organizations in user!'
  },
  error_saving_data: {
    id: 'error.saving_data',
    defaultMessage: 'Error saving data!'
  },
  success_enabled_mfwnet: {
    id: 'success.enabled_mfwnet',
    defaultMessage: 'MFWNet is enabled successfully!'
  },
  success_disabled_mfwnet: {
    id: 'success.disabled_mfwnet',
    defaultMessage: 'MFWNet is disabled successfully!'
  },
  rebrand_subscription_cancelled: {
    id: 'rebrand_subscription_canceled',
    defaultMessage: 'Rebrand Subscription Successfully Cancelled'
  },
})

export default messages;
