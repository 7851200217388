import { startOfMonth , endOfMonth, subDays, addDays} from 'date-fns';

export const validateYouTubeLink = (link: string): boolean => {
  const youtubeRegex = /^(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
  const youtubeShortsRegex = /^(https?:\/\/)?(www\.youtube\.com)\/shorts\/.+$/;

  return youtubeRegex.test(link) || youtubeShortsRegex.test(link);
};

export const formatYoutubeLink = (videoUrl: string) => {
  let formattedUrl = '';

  if (videoUrl) {
    const videoIdMatch = videoUrl.match(/[?&]v=([^&]+)|\/shorts\/([^&]+)/);
    
    if (videoIdMatch) {
      const videoShortsId = videoIdMatch[1] ? videoIdMatch[1] : videoIdMatch[2];
      formattedUrl = `https://www.youtube.com/embed/${videoShortsId}`;
    } else if (videoUrl.startsWith('https://youtu.be/')) {
      const videoId = videoUrl.split('https://youtu.be/')[1].split('&')[0];
      formattedUrl = `https://www.youtube.com/embed/${videoId}`;
    }
  }

  return formattedUrl;
}

export const calculateFirstMonthDay = (date: Date)=>{
  return startOfMonth(date)
}

export const calculateLastMonthDay = (date: Date)=>{
  return endOfMonth(date)
}

export const calculateFirstMonthDayForClientCalendar = (date: Date) => {
  // We go back up to 6 days to load data from previous month too
  return subDays(calculateFirstMonthDay(date), 6);
}

export const calculateLastMonthDayForClientCalendar = (date: Date) => {
  // We go forward up to 6 days to load data from next month too
  return addDays(calculateLastMonthDay(date), 6);
}
