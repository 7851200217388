import React, { Fragment } from 'react';
import Hidden from '@material-ui/core/Hidden';
import {AppBar, createStyles, makeStyles, Theme} from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import DrawerContent from './DrawerContent';
import MenuItem from '../../model/MenuItem';
import {useStoreState} from "pullstate";
import globalState from "../../globalState";
import { useMfwThemeProvider } from 'myfitworld-utils';
import { MenuType } from '../../types/other';
import A2hscDialog from "../dialogs/a2hsDialog";

const drawerWidth = 92;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      backgroundColor: theme.palette.background.default,
    },
  })
);

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

const NavigationDrawer = (
  { 
    menuItems: unfilteredMenuItems,
    menuType 
  } :
  { 
    menuItems: MenuItem[],
    menuType?: MenuType
   }
  ) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const currentOrganization = useStoreState(globalState, s => s.currentOrganization);
  const {theme} = useMfwThemeProvider();
  const menuItems = unfilteredMenuItems.filter(r => {
    if (r.condition) {
      return r.condition(currentOrganization);
    }
    return true;
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Fragment>
      <Hidden smUp implementation='css'>
        <AppBar position='fixed'>
          <Toolbar>
            <IconButton color='inherit' aria-label='open drawer' onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
            <Typography variant='h6' noWrap>{theme.whitelabel.name}</Typography>
            <A2hscDialog/>
          </Toolbar>
        </AppBar>
      </Hidden>
      <Hidden smUp implementation='css'>
        <Drawer
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          className={classes.drawer}
          classes={{ paper: classes.drawer }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <DrawerContent menuItems={menuItems} onClose={handleDrawerToggle} menuType={menuType}/>
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation='css'>
        <Drawer
          id='appNavigationDrawer'
          className={classes.drawer}
          classes={{ paper: classes.drawer }}
          variant='permanent'
          open
        >
          <DrawerContent menuItems={menuItems} onClose={handleDrawerToggle} menuType={menuType}/>
        </Drawer>
      </Hidden>
    </Fragment>
  );
};

export default NavigationDrawer;
