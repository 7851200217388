import {  defineMessages } from 'react-intl'

const messages = defineMessages({
   premium_content: {
    id: 'messages.switches.premium_content',
    defaultMessage: 'PREMIUM Content'
   },
   archive_meal: {
      id: 'nutrition_plan.archive_meal',
      defaultMessage: 'Archive Meal'
   },
   unarchive_meal: {
      id: 'nutrition_plan.unarchive_meal',
      defaultMessage: 'Unarchive Meal'
   },
   unarchive_plan: {
      id: 'nutrition_plan.unarchive_plan',
      defaultMessage: 'Unarchive Plan'
   },
   archive_plan: {
      id: 'nutrition_plan.archive_plan',
      defaultMessage: 'Archive Plan'
   },
   unarchive_meal_question: {
      id: 'nutrition_plan.unarchive_meal_question',
      defaultMessage: 'Do you really want to unarchive this meal?'
   },
   archive_meal_question: {
      id: 'nutrition_plan.archive_meal_question',
      defaultMessage: 'Do you really want to archive this meal?'
   },
   archive_plan_question: {
      id: 'nutrition_plan.archive_plan_question',
      defaultMessage: 'Do you really want to archive this plan?'
   },
   unarchive_plan_question: {
      id: 'nutrition_plan.unarchive_plan_question',
      defaultMessage: 'Do you really want to unarchive this plan?'
   },
   unarchive_workout_question: {
      id: 'workout.unarchive_workout_question',
      defaultMessage: 'Do you really want to unarchive this workout?'
   },
   archive_workout_question: {
      id: 'workout.archive_workout_question',
      defaultMessage: 'Do you really want to archive this workout?'
   },
   unarchive_program_question: {
      id: 'program.unarchive_program_question',
      defaultMessage: 'Do you really want to unarchive this program?'
   },
   archive_program_question: {
      id: 'program.archive_program_question',
      defaultMessage: 'Do you really want to archive this program?'
   },
   unarchive_workout: {
      id: 'workout.unarchive_workout',
      defaultMessage: 'Unarchive Workout'
   },
   archive_workout: {
      id: 'workout.archive_workout',
      defaultMessage: 'Archive Workout'
   },
   unarchive_program: {
      id: 'program.unarchive_program',
      defaultMessage: 'Unarchive Program'
   },
   archive_program: {
      id: 'program.archive_program',
      defaultMessage: 'Archive Program'
   },
   public_feed_filter_title: {
      id: 'messages.switches.public_feed_filter_title',
      defaultMessage: '{netName} Content'
   },
   motor_skills: {
      id: 'motor_skills',
      defaultMessage: 'Motor Skills'
   },
   sports: {
      id: 'Sports',
      defaultMessage: 'Sports'
   },
   muscle_regime_label: {
      id: 'MuscleRegimeLabel',
      defaultMessage: 'Muscle Regime'
   },
   primary_muscle_groups: {
      id: 'primary_muscle_groups',
      defaultMessage: 'Primary Muscle Groups'
   },
   secondary_muscle_groups: {
      id: 'secondary_muscle_groups',
      defaultMessage: 'Secondary Muscle Groups'
   },
   activity: {
      id: 'Activity',
      defaultMessage: 'Activity'
   },
   suitable_for_health_issues: {
      id: 'Suitable for health issues',
      defaultMessage: 'Suitable for Health Issues'
   },
   equipment: {
      id: 'Equipment',
      defaultMessage: 'Equipment'
   },
   time_preparation: {
      id: 'nutrition_plan.time_preparation',
      defaultMessage: 'Time Preparation'
   },
   duration_in_weeks: {
      id: 'nutrition_plan.duration_in_weeks',
      defaultMessage: 'Duration in weeks'
   },
   include_wl_content: {
      id: 'include_wl_content',
      defaultMessage: 'Include {name} Content'
   },
   show_wl_originals_filter_title: {
      id: 'messages.switches.show_wl_originals_filter_title',
      defaultMessage: '{name} Content'
   },
   show_wl_originals_filter: {
      id: 'messages.switches.show_wl_originals_filter',
      defaultMessage: 'By turning this filter ON, you include all {name} content, into your search'
   },
   show_org_originals: {
      id: 'Show ORG Originals',
      defaultMessage: 'Include Organization Content'
   },
   show_org_originals_filter: {
      id: 'messages.switches.show_org_originals_filter',
      defaultMessage: 'Show Organization Content'
   },
   show_org_originals_filter_title: {
      id: 'messages.switches.show_org_originals_filter_title',
      defaultMessage: 'Show Organization Content'
   },
   public_feed_filter: {
      id: 'messages.switches.public_feed_filter',
      defaultMessage: 'By turning this filter ON, you enter public feed search – you have the access to all the content that was marked as {netName} by any professional author (individual or organization) on {name} Platform'
   },
   reset_all_filters: {
      id: 'button.reset_all_filters',
      defaultMessage: 'Reset All Filters'
   },
   searchfield_label: {
      id: 'workout.contentfilter.searchfield.label',
      defaultMessage: 'Search by name'
   },
   filters: {
      id: 'workout.contentfilter.filters',
      defaultMessage: 'Filters'
   },
   meal_types: {
      id: 'nutrition_plan.meal_types',
      defaultMessage: 'Meal Types'
   },
   dynamic: {
      id: 'MuscleRegime.DYNAMIC',
      defaultMessage: 'Dynamic'
   },
   isometric: {
      id: 'MuscleRegime.ISOMETRIC',
      defaultMessage: 'Isometric'
   },
   combined: {
      id: 'MuscleRegime.COMBINED',
      defaultMessage: 'Combined'
   },
   first_workout_template: {
      id: 'workout.content_workout.first_workout_template',
      defaultMessage: 'Setup Your First Workout Template.'
   },
   exercise: {
      id: 'exercise.exercise',
      defaultMessage: 'Exercise'
   },
   preview: {
      id: 'client.client_form.preview',
      defaultMessage: 'Preview'
   },
   add_exercises: {
      id: 'add_exercises',
      defaultMessage: 'Add Exercise(s)'
   },
   add_workouts: {
      id: 'add_workouts',
      defaultMessage: 'Add Workout(s)'
   },
   duration: {
      id: 'equipment_file.duration',
      defaultMessage: 'Duration'
   },
   rest_times_label: {
      id: 'workout.edit.rest_times.rest_times_label',
      defaultMessage: 'Rest Times'
   },
   inter_exercise: {
      id: 'workout.edit.rest_times.inter_exercise',
      defaultMessage: 'Inter-exercise Rest'
   },
   inter_round: {
      id: 'workout.edit.rest_times.inter_round',
      defaultMessage: 'Inter-round Rest'
   },
   inter_group: {
      id: 'workout.edit.rest_times.inter_group',
      defaultMessage: 'Inter-group Rest'
   },
   create_new_workout: {
      id: 'workout.content_workout.create_new_workout',
      defaultMessage: 'Create New Workout'
   },
   create_workout: {
      id: 'workout.content_workout.create_workout',
      defaultMessage: 'Create Workout'
   },
   main_workout: {
      id: 'workout.content_workout.main_workout',
      defaultMessage: 'Main Workout'
   },
   add_group: {
      id: 'workout.content_workout.add_group',
      defaultMessage: 'Add Group'
   },
   time: {
      id: 'workout.time',
      defaultMessage: 'Time'
   },
   remove_exercise: {
      id: 'workout.remove_exercise',
      defaultMessage: 'Remove Exercise'
   },
   remove_exercise_question: {
      id: 'workout.remove_exercise_question',
      defaultMessage: 'Do you really want to remove this exercise?'
   },
   delete_round: {
      id: 'workout.delete_round',
      defaultMessage: 'Delete Round'
   },
   copy_round: {
      id: 'workout.copy_round',
      defaultMessage: 'Copy Round'
   },
   delete_group: {
      id: 'workout.delete_group',
      defaultMessage: 'Delete Group'
   },
   duplicate_group: {
      id: 'workout.duplicate_group',
      defaultMessage: 'Duplicate Group'
   },
   delete_group_question: {
      id: 'workout.delete_group_question',
      defaultMessage: 'Are you sure you want to delete this Group?'
   },
   add_round: {
      id: 'workout.add_round',
      defaultMessage: 'Add Round'
   },
   group_add_exercise: {
      id: 'workout.group_add_exercise',
      defaultMessage: 'Add Exercise to'
   },
   muscle_groups: {
      id: 'equipment_file.muscle_groups',
      defaultMessage: 'Muscle Groups'
  },
  there_is_a_problem_with_your_stripe_connected_account : {
	id : "stripe_connect.there_is_a_problem_with_your_stripe_connected_account",
	defaultMessage : "There is a problem with your stripe connected account, login to dashboard to fix"
   },
   you_can_lock_your_content_set_price_and_sell_on_mfw_marketplace : {
      id : "stripe_connect.you_can_lock_your_content_set_price_and_sell_on_mfw_marketplace",
      defaultMessage : "You can lock your content, set price and sell on MyFitWorld marketplace."
   },
   content_price : {
      id : "stripe_connect.content_price",
      defaultMessage : "Content Price"
   },
   myContentOnMarketplace : {
      id : "stripe_connect.marketplace.myContentOnMarketplace",
      defaultMessage : "My Content on Marketplace"
   },
   onlyAdminsAllowed : {
      id : "stripe_connect.marketplace.onlyAdminsAllowed",
      defaultMessage : "Only Admins in your organization who are linked via Stripe Connect have the ability to lock content and set prices."
   },
   my_marketplace_content: {
      id : "stripe_connect.my_marketplace_content",
      defaultMessage : "My Marketplace Content"
   },
   locked_content_info_message : {
      id : "stripe_connect.locked_content_info_message",
      defaultMessage : "This means that the content is not available for use without payment. You can view the purchase details by clicking on the content preview. The price is set by the content owner. Owner’s profile can be checked by cliking on the profile picture in the right corner of the content."
   },
   marketplaceMin : {
      id : "stripe_connect.marketplace.marketplaceMin",
      defaultMessage : "Min"
   },
   marketplaceMax : {
      id : "stripe_connect.marketplace.marketplaceMax",
      defaultMessage : "Max"
   },
   marketplaceMinPrice : {
      id : "stripe_connect.marketplace.marketplaceMinPrice",
      defaultMessage : "Minimum Price"
   },
   marketplaceMaxPrice : {
      id : "stripe_connect.marketplace.marketplaceMaxPrice",
      defaultMessage : "Maximum Price"
   },
   marketplaceSortByPrice : {
      id : "stripe_connect.marketplace.marketplaceSortByPrice",
      defaultMessage : "Sort By Price"
   }
})
export default messages;