import { Box } from "@material-ui/core";
import React from "react";
import LabelIcon from '@material-ui/icons/Label';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import globalState from "../globalState";
import { useStoreState } from "pullstate";
import { useMfwThemeProvider } from "myfitworld-utils";

export default () => {
  const currentOrganization = useStoreState(globalState, s => s.currentOrganization);
  const discountNumber = currentOrganization?.stripe?.discountName?.slice(0, 2);
  const theme = useMfwThemeProvider();
  if (currentOrganization?.stripe?.discountName) {
    return (
      <Box style={{ position: 'relative' }}>
        <AccountBalanceWalletIcon style={{ display: 'block', color: theme.mfwMuiWebTheme.palette.primary.main }}/>
        <LabelIcon
          style={{ position: 'absolute', bottom: 10, left: 10 }}
          color='primary'
          fontSize='medium'
        />
        <LabelIcon
          style={{ position: 'absolute', bottom: 10, left: 5 }}
          color='primary'
          fontSize='medium'
        />
        <Box style={{color: 'black', fontSize: '0.6rem', position: 'absolute', bottom: 13, left: 10}}>
          {discountNumber}%
        </Box>
      </Box>
    );
  } else {
    return (
      <AccountBalanceWalletIcon/>
    );
  }
}
