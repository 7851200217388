import {  defineMessages } from 'react-intl'

const messages = defineMessages({
    access_is_invitation_only: {
        id: 'access_is_invitation_only',
        defaultMessage: 'Access is invitation only, please check with your admin to share you the invitation'
    },
    show_wl_originals_filter_title: {
        id: 'messages.switches.show_wl_originals_filter_title',
        defaultMessage: '{name} Content'
    },
    org_content_title: {
        id: 'messages.switches.org_content_title',
        defaultMessage: 'Organization Content'
    },
    premium_reports: {
        id: 'premium_reports',
        defaultMessage: 'Premium Reports'
    },
    premium_code: {
        id: 'premium_code',
        defaultMessage: 'Premium Code'
    },
    premium_subscription_type: {
        id: 'premium_subscription_type',
        defaultMessage: 'Subscription Type'
    },
    from: {
        id: 'statistics.from',
        defaultMessage: 'FROM'
    },
    to: {
        id: 'statistics.to',
        defaultMessage: 'TO'
    },
    search: {
        id: 'statistics.search',
        defaultMessage: 'SEARCH'
    },
    monthly: {
        id: 'subscription.monthly',
        defaultMessage: 'MONTHLY'
    },
    yearly: {
        id: 'subscription.yearly',
        defaultMessage: 'YEARLY'
    },
    active: {
        id: 'premium.active',
        defaultMessage: 'ACTIVE'
    },
    unactive: {
        id: 'premium.unactive',
        defaultMessage: 'UNACTIVE'
    },
    type: {
        id: 'organization.type',
        defaultMessage: 'Type'
    },
})

export default messages;
