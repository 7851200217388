export enum configMainRoutesConsts {
    root = "/",
    rootAny = "*",
    content = "/content",
    nutritionPlan = "/nutritionPlan",
    configuration = "/configuration",
    organization = "/organization",
    clients = "/clients",
    tutorials = "/tutorials",
    schedule = "/schedule",
    profile = "/profile",
    billing = "/billing",
    connect = "/Connect",
    marketplace = "/marketplace/:language",
    marketplaceWithoutLanguage = "/marketplace", 
    marketplacePublicProfilePage = "/marketplace/p/:myUrl/:language",
    marketplaceProductPage = "/marketplace/product/:productType/:productId", //where productType:  program , workout
    organizations = "/organizations",
    users = "/users",
    superadminReports = "/superAdminReports",
    corporateOrganizations = "/corporateOrganizations",
    stripeConnect = "/stripeConnect",
    orgProfile = "/orgProfile",
    myProfile = "/profile",
    signout = "/signout",
    emailResetPasswordPage = "/reset-password",
    languageAttribute = "/:language",
    corpClientsCorpId = "/corpClients/:corpId",
    premium = "/premium",
    publicProfilePage = "/t/:myUrl/:language",
    publicOrgProfilePage = "/t/org/:myUrl/:language",
    invitationPage = "/i/:invitationCode",
    clientFormContainerPage = "/clients/:userId",
    invitationFormContainerPage = "/invitations/:invitationId",
    clientStatisticPage = "/clients/:userId/statistics/:workoutId",
    clientDailyActivityInfo = "/clients/:userId/dailyActivity/:activityId",
    clientRichStatisticPage = "/clients/statistics/:userId",
    clientMetricHistory = "/clients/metric-history/:userId",
    privacyPolicyAndTermsOfServicesPage = "/pp-tos/:language",
    trainerOrganizationLoginPage = "/registration/:language",
    createUserAccount = "/user/create-account/:language",
    createAdminAccount = "/user/create-admin-account/:language",
}

export enum configRoutesMenuLabels {
    organizationsManagementPage = "organizations",
    nutritionPlanPage = "nutrition_plan",
    superAdminUserManagementPage = "users",
    corpOrganizationsManagementPage = "corpOrg",
    reportsManagementPage = "reports",
    contentManagementPage = "content",
    configurationManagementPage = "configuration",
    myOrganizationPage = "my_org",
    clientManagementPage = "clients",
    tutorialsPage = "tutorials",
    schedulePage = "my_schedule",
    editProfilePage = "my_profile",
    billingPage = "billing",
    stripeConnectPage = "stripe_connect",
    editOrgProfilePage = "org_profile",
    marketplaceManagementPage = "marketplace",
    clientLandingPage = "home", 
    premiumPage = "premium"
}

export enum configMainRoutesQueryParamConsts{
    formOpen = "form=open",
    enableClientRegistration = "enable_client_register=true",
    marketplaceWorkout = "marketplace=workout-:workoutId",
    marketplaceProgram = "marketplace=program-:programId"
}

export const buildUrl = (urlString:string, attributes:string[]) => {
    const matches = urlString.match(/:[a-zA-Z0-9]+/g);
    if (!matches || matches.length !== attributes.length) {
        throw new Error('Number of attributes need to be the same as urString!!');
    }

    let replacedUrl = urlString;
    for (let i = 0; i < matches.length; i++) {
        replacedUrl = replacedUrl.replace(matches[i], attributes[i]);
    }

    return replacedUrl;
}

export const isUrlTemplateMatch = (template: string, inputString: string) => {
  /*
  example : 

  template : "/registration/:language"
  
  for inputString :
  inputString : "/registration/en" - true
  input : "/registration" - false
  */
  const templateParts = template.split("/");
  const inputParts = inputString.split("/");

  if (templateParts.length !== inputParts.length) {
    return false;
  }

  for (let i = 0; i < templateParts.length; i++) {
    if (templateParts[i].startsWith(":")) {
      continue;
    }
    if (templateParts[i] !== inputParts[i]) {
      return false;
    }
  }

  return true;
};